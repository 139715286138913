footer {
  padding: 25px 0px;
}

.happ-logo {
  width: 169px;
  height: 63px;
  margin-bottom: 4%;
}
.footer-icons {
  width: 25px;
  height: 20.38px;
  color: #2cbbbf;
  margin-right: 10px;
  margin-left: 0px;
}
.footer-icons:hover {
  cursor: pointer;
  transform: scale(1.2);
}
.footer-links {
  font-size: 19px;
  color: #191919;
  font-weight: 500;
  cursor: pointer;
}
.footer-heads {
  margin-bottom: 8%;
  font-size: 24px;
  color: #e32227;
  font-weight: 900;
}
.footer-search {
  width: 205px;
  height: 50px;
  border-radius: 40px;
  text-align: start;
  padding-left: 15px;
  border: none;
  background-color: #eee7e6;
  margin-right: 20px;
}
.footer-mail {
  width: 45vw;
  height: 50px;
  font-size: 11px;
  border-radius: 40px;
  background-color: #e32227;
  color: #fff;
  border: none;
}
.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 60px;
  background: rgba(28, 200, 200, 1);
}
.bottom-text {
  font-size: 18px;
  font-weight: 700;
  padding: 30px 85px;
}
.bottom-icons-sec {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 100px;
}
.bottom-icons {
  width: 36px;
  height: 34px;
  background-color: #eee7e6;
  margin-right: 10px;
  padding: 8px;
}
.bottom-icons:hover {
  cursor: pointer;
  background-color: #d1e0e0;
}

/* Media Queries */

@media screen and (max-width: 550px) {
  .bottom-icons {
    display: none;
  }
  .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .footer-mail {
    width: 35vw;
    height: 4vh;
    font-size: 1rem;
  }
  .bottom-text {
    padding: 0;
    text-align: center;
    font-weight: 600;
  }
}
@media screen and (max-width: 768px) {
  .bottom-icons {
    display: none;
  }
}