.Foodies {
  background: linear-gradient(
    to right,
    rgba(28, 200, 200, 0.05),
    rgba(28, 200, 200, 0.25)
  );
  padding-top: 10%;
  padding-bottom: 3%;
}
.Foodies-sec > h3 {
  color: #e32227;
  font-size: 2.5rem;
  font-weight: 700;
  overflow: hidden;
}

.Foodies-sec > p {
  font-size: 1.2rem;
}
.Foodies-sec-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.Foodies-btn-1 {
  font-size: 1.5rem;
  color: #fff;
  background-color: #e32227;
  width: 10vw;
  height: 8vh;
  border-radius: 40px;
  border: none;
}
.Foodies-btn-2 {
  font-size: 1.5rem;
  color: #fff;
  background-color: #2cbbbf;
  width: 10vw;
  height: 8vh;
  border-radius: 40px;
  border: none;
}

/*------------------Media Queries------------*/
@media screen and (max-width: 1100px) {
  .Foodies {
    padding-top: 15%;
  }
}

@media screen and (max-width: 620px) {
  .Foodies {
    padding-top: 25%;
  }
}

@media screen and (max-width: 768px) {
  .Foodies-sec-btn {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
  }
  .Foodies-btn-1 {
    font-size: 1.5rem;
    color: #fff;
    background-color: #e32227;
    width: 20vw;
    height: 8vh;
    border-radius: 40px;
    border: none;
  }
  .Foodies-btn-2 {
    font-size: 1.5rem;
    color: #fff;
    background-color: #2cbbbf;
    width: 20vw;
    height: 8vh;
    border-radius: 40px;
    border: none;
  }

}

@media screen and (max-width: 420px) {
  .Foodies {
    padding-top: 30%;
  }
  .Foodies-btn-1 {
    font-size: 1.5rem;
    color: #fff;
    background-color: #e32227;
    width: 25vw;
    height: 8vh;
    border-radius: 40px;
    border: none;
  }
  .Foodies-btn-2 {
    font-size: 1.5rem;
    color: #fff;
    background-color: #2cbbbf;
    width: 25vw;
    height: 8vh;
    border-radius: 40px;
    border: none;
  }
}
