.MyMenu {
  background: linear-gradient(
    to right,
    rgba(28, 200, 200, 0.05),
    rgba(28, 200, 200, 0.25)
  );
  padding-top: 8%;
}

.accordion-button:not(.collapsed) {
  color: #e32227;
  font-size: 2.5rem;
  font-weight: 700;

}

.accordion-button{

  color: #e32227;
}

.MyMenu-sec > h3 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #e32227;
}

.MyMenu-sec > h4 {
  font-size: 2rem;
  font-weight: 700;
  color: #e32227;
}
/*---------------Menu Section------------------------------------*/
.board_bg {
  background-image: url("../../images/board2.png");
  background-repeat: no-repeat;
  background-size: cover;
  /* object-fit: scale-down; */
  background-position: center;
  max-width: 100%;
  min-height: 150vh;
}

/*---------------Menu Section------------------------------------*/

.Menu-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
  position: relative;
}

.slider_food {
  margin-top: 180px;
}

.nav-item {
  width: 254px;
  font-size: 24px;
  font-weight: 500;
  border-radius: 40px !important;
  margin-bottom: 15%;
  color: #191919 !important ;
  position: relative;
}

.nav-link {
  color: #191919 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #e32227 !important;
  border-radius: 40px !important;
  color: #fff !important;
  height: 85px;
  padding: 20px;
  overflow: visible;
}

.Menu_image {
  position: absolute;
  margin-left: 45px;
  width: 228px;
  height: 228px;
  border-radius: 50%;
  background: transparent;
  border: 12px solid rgb(173, 240, 241);
  padding: 5px;
  z-index: 3;
}

.Menu_details {
  position: relative;
  margin-top: 25%;
  width: 324px;
  height: 372px;
  border-radius: 20px;
  border: 2px solid #1cc8c8;
  box-shadow: 0px 4px 10px 1px rgba(245, 71, 72, 0.25);
  background: linear-gradient(
    -180.41987631903deg,
    rgb(231, 231, 231) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.Menu-price {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  overflow: hidden;
  font-family: Poppins;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  background: rgba(194, 24, 7, 1);
  z-index: 7;
  margin-left: 60%;
  margin-top: 15%;
}
.Menu-heart {
  position: absolute;
  width: 36px;
  height: 34px;
  right: 0;
  margin-right: 5%;
  margin-top: 5%;
  z-index: 7;
  cursor: pointer;
}
.Menu-heart:hover {
  color: #e32227;
  border: none;
}
.Menu-display-1 {
  position: absolute;
  margin-top: 141px;
  margin-left: 75px;
  width: 42px;
  height: 42px;
  border-radius: 19px / 19px;
  border: 2px solid #ffffff;
  background: rgb(230, 189, 189);
  z-index: 8;
}
.Menu-display-2 {
  position: absolute;
  margin-top: 141px;
  margin-left: 95px;
  width: 42px;
  height: 42px;
  border-radius: 19px / 19px;
  border: 2px solid #ffffff;
  background: rgb(230, 189, 189);
  z-index: 9;
}
.Menu-display-3 {
  position: absolute;
  margin-top: 141px;
  margin-left: 115px;
  width: 42px;
  height: 42px;
  border-radius: 19px / 19px;
  border: 2px solid #ffffff;
  background: rgb(230, 189, 189);
  z-index: 10;
}
.Menu-star {
  position: absolute;
  margin-top: 151px;
  margin-left: 175px;
  width: 25px;
  height: 24px;
  z-index: 10;
}
.Menu-rating {
  position: absolute;
  margin-top: 151px;
  margin-left: 215px;
  z-index: 10;
}
.Menu-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-top: 191px;
  margin-left: 30px;
  z-index: 10;
}
.Menu-description-head {
  overflow: hidden;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #f54748;
}
.Menu-description-para {
  width: 256px;
  height: 83px;
  overflow: hidden;
  font-family: Poppins;
  font-size: 16px;
  text-align: center;
  color: #191919;
}
.Menu-buttons {
  position: absolute;
  margin-top: 100%;
  margin-left: 28%;
  width: 131px;
  height: 45px;
  -ms-border-radius: 100px;
  -o-border-radius: 100px;
  -moz-border-radius: 100px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  background: rgba(194, 24, 7, 1);
  border: none;
  color: #fff;
  font-weight: 600;
  z-index: 11;
}

.Menu-list {
  display: flex;
  flex-direction: column;
  position: relative;
}

.react-tabs {
  display: flex;
  margin-left: 33px;
  width: 470px;
  height: 400px;
  border: none;
  color: black;
  background: transparent;
  z-index: 10;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  min-height: 140vh;
  margin: 80px 80px 80px 0px;
  font-size: 28px;
  padding-right: 50px;
  color: black;
  background: transparent;
}

.react-tabs__tab {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 254px;
  height: 85px;
  list-style: none;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10%;
  padding: 20px 20px;
  text-align: center;
  border-radius: 40px;
  cursor: pointer;
  color: black;
}

.react-tabs__tab--selected {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: #e32227;
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  padding: 20px 20px;
  margin-bottom: 10%;
  border-radius: 40px;
  border: none;
  /* border-right: 8px solid #2cbbbf; */
  color: white;
}
.react-tabs__tab--selected:focus-within {
  border: none !important;
}

.react-tabs__tab-panel {
  display: block;
}

.react-tabs__tab-panel--selected {
  /* position: absolute; */
  padding: 10%;
}
.Menu-icons {
  width: 48px;
  height: 48px;
  background-color: azure;
  padding: 7px;
  margin-right: 10px;
  border-radius: 50%;
}
.panel-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* margin: 80px 200px; */
  z-index: 5;
}
.Menu-img {
  width: 100%;
  position: absolute;
  z-index: 5;
}
.Menu-board {
  max-width: 100%;
  margin-left: 10%;
  z-index: -2;
}

/*------------Media Queries---------------*/

@media screen and (max-width: 768px) {
  .MyMenu-sec {
    padding-top: 10%;
  }

  .Menu-food-name {
    display: none;
  }
}

@media screen and (max-width: 420px) {
  .MyMenu-sec {
    padding-top: 25%;
  }
  .Menu-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    position: relative;
  }
  .Menu-Food-list {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .Menu-food-name{
    display: none;
  }
  .Menu-heart {
    display: none;
  }
  .Menu-icons {
    width: 38px;
    height: 38px;
    background-color: azure;
    padding: 2px;
    margin-right: 0;
    border-radius: 50%;
  }
}
