.Stores {
  padding-top: 5%;
  width: 100%;
  object-fit: contain;
}
.Store-image {
  width: 100%;
  height: 100%;
  margin-top: 29px;
  position: relative;
  z-index: 2;
}
.Store-plant {
  position: absolute;
  right: 4%;
  padding-top: 80px;
}
.Store-header {
  width: 80vw;
  font-weight: 700;
  font-size: 48px;
  /* line-height: 1.5; */
}
.Store-desc {
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  overflow: hidden;
  color: #191919;
  position: relative;
}
.Store-card {
  text-align: center;
  border-radius: 20px;
  top: 0;
  left: 0;
  width: 302px;
  height: 420px;
  border: 1px solid #d8d3d3;
  margin: 30;
  background: rgba(255, 255, 255, 1);
  box-shadow: 2px 2px 10px -1px rgb(0 0 0 /30%);
}
.Store-section {
  /* border-radius: 10px; */
  width: 45vw;
  /* box-shadow: 1px 2px 10px -1px rgb(0 0 0 /30%); */
  /* border: 1px solid red; */
}
.Store-card-header {
  padding-top: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #c21807;
}

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: aliceblue;
  z-index: 1;
  margin-top: 43%;
  color: #e32227;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #e32227 !important;
  background: aliceblue !important;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}
.Store-icons {
  width: 25px;
  height: 20.38px;
  color: #2cbbbf;
  margin-right: 10px;
  margin-left: 0px;
}
.Store-icons:hover {
  cursor: pointer;
  transform: scale(1.3);
}
.Store-blur {
  position: absolute;
  z-index: -1;
  top: 70%;
  left: 0.5%;
}
.Store-arrows {
  font-size: 30px;
  color: #e32227;
}
.Store-bar {
  width: 100%;
  /* height: 100%; */
  height: 10vh;
  margin-top: 2%;
  background-color: grey;
}

/*------------Media Queries--------------*/
@media screen and (max-width: 550px) {
  .Store-header {
    font-size: 38px;
  }
  .Store-desc {
    font-size: 12px;
  }
  .Store-section {
    width: 90vw;
    margin: 0;
    padding: 0;
  }
  .Store-card {
    width: 90vw;
    height: 320px;
    margin-right: 50px;
  }
  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    display: none;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus,
  .Store-blur,
  .Store-plant {
    display: none;
  }
  .Store-image {
    width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .Store-section {
    width: 90vw;
    margin: 0;
    padding: 0;
  }
  .Store-card {
    width: 40vw;
    height: 520px;
    margin-right: 50px;
  }
}
