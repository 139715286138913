.MyStores {
  background: linear-gradient(to right,
      rgba(28, 200, 200, 0.05),
      rgba(28, 200, 200, 0.25));
  padding-top: 8%;
}

.MyStores-sec {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.MyStores-head {
  font-size: 2.5rem;
  font-weight: 700;
  color: #e32227;
}

.slick-slide>div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}

.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  display: none !important;
}

.slick-prev::before,
.slick-next::before {
  display: none !important;
}

.MyStores-desc {
  line-height: 28px;
  text-align: justify;
}

.MyStore-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 25vw;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid #8bf1ff;
  /* box-shadow: 2px 2px 10px -1px rgb(0 0 0 /30%); */
  overflow: hidden;
  margin-top: 16px;
  margin-bottom: 40px;
  /* border: 1px solid black; */
}

.MyStore-cardimage {
  width: 100%;
  height: 40vh;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.MyStore-cardtop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MyStore-cardtop>h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #e32227;
  margin-bottom: 20px;
}

.MyStore-cardbottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MyStore-cardbottom>h6 {
  font-size: 1rem;
  font-weight: 600;
}

.MyStore-cardbottom>p {
  font-size: 1rem;
}

@media screen and (max-width:420px) {
  .MyStores {
    padding-top: 40%;
  }

  .MyStores-sec {
    padding-top: 20%;
  }

}


@media screen and (max-width:768px) {
  .MyStores {
    padding-top: 15%;
  }

}

.headbg {
  background-color: rgb(236, 0, 0);
  margin-top: 100px;
  background-image: url('https://img.freepik.com/free-photo/indian-city-scene_23-2151823038.jpg?t=st=1729598466~exp=1729602066~hmac=8fa86b1f66172697a01eaa91eba354dc10777d794f4f293a3f0ae76b36d13fda&w=740');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: multiply;
  padding: 70px;
  text-align: center;
  color: #ffff;
 
}

.headbg h1 {

  font-weight: 600;

}

.Ab_images img{
  width: 100%;
  height: auto;
  border-radius: 10px;
  background-color: #e32227;
  
}

.Ab_images p {
  text-align: justify;
  margin-top: 20px;
  line-height: 27px;
  font-size: 15px;
}

.menu_img h2{
  text-align: center;
  color: #e32227;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 45px;
}

/* .menu_1{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;

} */


.menu_1 img{
  border-radius: 10px;
}

.menu_1 h5{
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 0;
}


.shorts_yt iframe {
  border-radius: 15px;
  margin-top: 50px;
}


.zo_head{
  text-align: center;
}

.zo_head h4{
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 22px;
}

.zo_head img{

  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  width: 80%;
  margin-bottom: 40px;
  border-radius: 20px;
  border: 1px solid #8bf1ff;

}



.call_to_action{
  border: 2px solid rgb(204, 204, 204);
  padding: 20px;
  border-radius: 15px;
}

.call_to_action .button1 {
  width: 100%;
  margin-bottom: 20px;
  background-color: #00ddfa;
  border: none;
  font-size: 23px;

}
.call_to_action .button2 {
  width: 100%;
  margin-bottom: 20px;
  background-color: #e32227;
  border: none;
  font-size: 23px;

}

.call_to_action .button1 .icons{
  font-size: 25px;
  margin-right: 10px;
}

.call_to_action .button2 .icons{
  font-size: 25px;
  margin-right: 10px;
}
.zo_head iframe{
  border-radius: 20px;
  margin-top: 50px;
  
}