.Faq-Section {
  background: linear-gradient(
    to left,
    rgba(28, 200, 200, 0.05),
    rgba(28, 200, 200, 0.25)
  );
  width: 100%;
}
.Faq-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
}
.faq-chef {
  max-width: 100%;
}
.Faq-chef-2 {
  display: none;
}
.faq-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  margin-top: 15%;
  padding: 0;
  overflow: hidden;
  max-width: 688px;
  min-height: 520px;
  -webkit-box-shadow: -1px 1px 5px 4px rgba(245, 71, 72, 0.25);
  -moz-box-shadow: -1px 1px 5px 4px rgba(245, 71, 72, 0.25);
  box-shadow: -1px 1px 5px 4px rgba(245, 71, 72, 0.25);
  background: rgba(255, 255, 255, 1);
}
.dropdown-group {
  display: flex;
  align-items: center;
  width: 400px !important;
  height: 30px !important;
  color: #191919 !important;
  background-color: white !important;
  border: none !important;
  border-radius: 0;
  border-bottom: 2px solid #2cbbf2 !important;
  justify-content: space-between !important;
  font-size: 24px !important;
  margin-top: 20%;
  margin-bottom: 20%;
  padding-top: 5%;
  padding-bottom: -15%;
}
.dropdown-toggle::after {
  display: none !important;
}
.dropdown-arrow {
  font-weight: 700px;
  font-size: 25px;
  position: absolute;
  top: -10px;
  bottom: 20px;
  right: -120px;
}

.dropdown-questions {
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  line-height: 20px;
  width: 50vw;
  margin-top: -4px;
  height: 46px;
  text-align-last: left;
  color: #252b42;
}
#dropdown-basic {
  background-color: transparent;
  border: none;
  /* width: 35px; */
}
.dropdown-menu {
  width: 330px;
  min-height: 20px;
  text-align: left !important;
  color: #191919 !important;
  background-color: white !important;
  border: none !important;
  border-radius: 0;
  font-size: 24px !important;
  margin-left: -80px;
  font-weight: 400;
  margin-top: -20px;
  /* margin-right: -40px !important; */
}
.dropdown-menu:hover {
  background-color: none !important;
}
.drop {
  position: relative;
  width: 20vw;
}

/*------------------Media Queries---------------------------------------------------------------------------*/

@media screen and (max-width: 550px) {
  .Faq-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    justify-content: center;
  }
  .faq-chef {
    display: none;
  }
  .Faq-chef-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-left: 5%;
  }
  .faq-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    margin-top: 5%;
    margin-left: 7%;
    padding: 0;
    overflow: hidden;
    width: 80vw;
    min-height: 80vw;
  }
  .dropdown-group {
    display: flex;
    align-items: center;
    width: 250px !important;
    height: 30px !important;
    color: #191919 !important;
    background-color: white !important;
    border: none !important;
    border-radius: 0;
    border-bottom: 2px solid #2cbbf2 !important;
    justify-content: space-between !important;
    font-size: 10px !important;
    margin-top: 20%;
    margin-bottom: 20%;
    padding-top: 5%;
    padding-bottom: -15%;
  }
  .dropdown-questions {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 20px;
    width: 50vw;
    margin-top: -4px;
    height: 46px;
    text-align-last: left;
    color: #252b42;
  }
  .dropdown-arrow {
    font-weight: 700px;
    font-size: 25px;
    position: absolute;
    top: -10px;
    bottom: 20px;
    right: -170px;
  }
  .dropdown-menu {
    width: 200px;
    min-height: 20px;
    text-align: center !important;
    color: #191919 !important;
    background-color: white !important;
    border: none !important;
    border-radius: 0;
    font-size: 14px !important;
    margin-left: -20px;
    font-weight: 400;
    margin-top: -20px;
    /* margin-right: -40px !important; */
  }
}
