.MyFranchise {
  background: linear-gradient(
    to right,
    rgba(28, 200, 200, 0.05),
    rgba(28, 200, 200, 0.25)
  );
  padding-top: 8%;
  padding-bottom: 3%;
}

.MyFranchise-sec > h1 {
  font-size: 2.5rem;
  color: #e32227;
  font-weight: 700;
}
.MyFranchise-sec > h3 {
  font-size: 2.5rem;
  color: #e32227;
  font-weight: 700;
}
.MyFranchise-sec > h4 {
  font-size: 2rem;
  color: #e32227;
  font-weight: 700;
}
.MyFranchise-usp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 1.2rem;
  color: #191919;
  overflow: hidden;
  font-weight: 500;
  margin-bottom: 3%;
}
.MyFranchise-usp1 {
  margin-right: 5%;
}
.MyFranchise-models > h4 {
  font-size: 1.5rem;
  color: #2cbbbc;
  font-weight: 600;
}
.MyFranchise-pick {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.MyFranchise-pick1 {
  width: 70%;
  height: auto;
}
.MyFranchise-pick2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 10%;
}
.MyFranchise-pick2-btn1 {
  width: 10vw;
  height: 5vw;
  border-radius: 40px;
  margin-bottom: 5%;
  font-size: 1.2rem;
  color: #fff;
  background-color: #e32227;
  border: none;
}
.MyFranchise-pick2-btn2 {
  width: 10vw;
  height: 5vw;
  border-radius: 40px;
  margin-bottom: 5%;
  font-size: 1.2rem;
  color: #fff;
  background-color: #2cbbbc;
  border: none;
}
.MyFranchise-pick2-btn3 {
  width: 10vw;
  height: 5vw;
  border-radius: 40px;
  margin-bottom: 5%;
  font-size: 1.2rem;
  color: #fff;
  background-color: purple;
  border: none;
}

/*--------------------Media Queries -------------*/

@media screen and (max-width: 1150px) {
  .MyFranchise {
    padding-top: 15%;
  }
}
@media screen and (max-width: 1000px) {
  .MyFranchise-pick {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
  }
  .MyFranchise-pick2-btn1 {
    width: 20vw;
    height: 5vw;
    border-radius: 40px;
    margin-bottom: 5%;
    font-size: 1.2rem;
    color: #fff;
    background-color: #e32227;
    border: none;
  }
  .MyFranchise-pick2-btn2 {
    width: 20vw;
    height: 5vw;
    border-radius: 40px;
    margin-bottom: 5%;
    font-size: 1.2rem;
    color: #fff;
    background-color: #2cbbbc;
    border: none;
  }
  .MyFranchise-pick2-btn3 {
    width: 20vw;
    height: 5vw;
    border-radius: 40px;
    margin-bottom: 5%;
    font-size: 1.2rem;
    color: #fff;
    background-color: purple;
    border: none;
  }
}
@media screen and (max-width: 700px) {
  .MyFranchise-pick {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .MyFranchise-pick2-btn1 {
    width: 40vw;
    height: 10vw;
    border-radius: 40px;
    margin-bottom: 5%;
    font-size: 1.2rem;
    color: #fff;
    background-color: #e32227;
    border: none;
  }
  .MyFranchise-pick2-btn2 {
    width: 40vw;
    height: 10vw;
    border-radius: 40px;
    margin-bottom: 5%;
    font-size: 1.2rem;
    color: #fff;
    background-color: #2cbbbc;
    border: none;
  }
  .MyFranchise-pick2-btn3 {
    width: 40vw;
    height: 10vw;
    border-radius: 40px;
    margin-bottom: 5%;
    font-size: 1.2rem;
    color: #fff;
    background-color: purple;
    border: none;
  }
}

@media screen and (max-width: 600px) {
  .MyFranchise {
    padding-top: 25%;
  }
  .MyFranchise-usp1 {
    margin-right: 0%;
  }
  .MyFranchise-pick {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
  }
  .MyFranchise-pick1{
    display: none;
  }
  .MyFranchise-pick2{
    margin: 0;
  }
  MyFranchise-pick-img {
    width: 200px;
    height: 200px;
  }
}
