.Banner-full {
  margin: 0;
  padding: 0;
  position: relative;
}

.banner-img {
  width: 100%;
}
.Banner-small {
  position: relative;
  display: none;
}
.Banner-desc-head {
  position: absolute;
  font-weight: 600;
  top: 40%;
  left: 50%;
  color: bisque;
  font-size: 3.5rem;
}
.banner-btn {
  position: absolute;
  width: 10rem;
  height: 62px;
  border-radius: 30px;
  top: 80%;
  left: 80%;
  font-weight: 600;
  border: 1px solid #c21807;
  background: rgba(25, 25, 25, 1);
  color: #fff;
}

/*Media Queries*/

@media screen and (max-width: 550px) {
  .banner-img {
    display: none;
  }
  .Banner-small {
    display: flex;
    position: relative;
    max-width: 100%;
    min-height: 558px;
  }
  .Banner-desc-head {
    position: absolute;
    font-size: 2rem;
    width: 9vw;
    top: 30%;
    /* left:40%; */
  }
  .banner-btn {
    /* display: none; */
    position: absolute;
    font-size: 0.9rem;
    font-weight: 400;
    top: 85%;
    left: 60%;
    width: 6rem;
    height: 35px;
    border: 1px solid turquoise;
  }
}

@media screen and (max-width:768px) {
  .Banner-desc-head {
    position: absolute;
    font-size: 1.7rem;
    width: 40vw;
    height: auto;
    top: 20%;
    /* left:40%; */
  }
  .banner-btn {
    /* display: none; */
    position: absolute;
    font-size: 1.2rem;
    font-weight: 400;
    top: 85%;
    left: 60%;
    width: 8rem;
    height: 35px;
    border: 1px solid turquoise;
  }
  
}