.Contact {
  background: linear-gradient(
    to right,
    rgba(28, 200, 200, 0.05),
    rgba(28, 200, 200, 0.25)
  );
  padding-top: 8%;
  padding-bottom: 3%;
}
.Contact-sec > h1 {
  font-size: 2.5rem;
  color: #e32227;
  font-weight: 700;
}

/*-------------Media Queries------------------*/

@media screen and (max-width:768px) {
  .Contact-sec{
    padding-top: 10%;
  }
  
}
@media screen and (max-width:420px) {
  .Contact-sec{
    padding-top: 25%;
  }
  
}