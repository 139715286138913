.about-us {
  background: linear-gradient(
    to right,
    rgba(28, 200, 200, 0.05),
    rgba(28, 200, 200, 0.25)
  );
  width: 100%;
}
.chef-about {
  padding-top: 100px;
  padding-bottom: 100px;
}
.chef-img-pose {
  position: relative;
  width: 100%;
  object-fit: cover;
  margin-left: -55px;
}
.chef-img-arrow {
  position: absolute;
  width: 405px;
  height: 118px;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: -115px;
}
.chef-desc {
  margin-top: 120px;
}
.chef-desc-header {
  /* width: 505px; */
  font-weight: 700;
  font-size: 38px;
  position: relative;
}
.chef-blur {
  position: absolute;
  z-index: -4;
  left: 80%;
  top: 130%;
}
.chef-desc-para {
  opacity: 0.8;
  top: 285px;
  left: 662px;
  max-width: 713px;
  height: 94px;
  overflow: hidden;
  font-size: 16px;
  text-align: left;
  color: #191919;
}
.chef-desc-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 20px;
  color: #191919;
  overflow: hidden;
  font-weight: 600;
}
.chef-desc-list-1 {
  margin-right: 75px;
  margin-left: 0;
}
.chef-desc-list-2 {
  margin-right: 25px;
  margin-left: 0;
}
.chef-login {
  width: 114px;
  height: 45px;
  margin-top: 15px;
  border-radius: 40px;
  border: none;
  color: #fff;
  font-weight: 400;
  background-color: #e32227;
  transition: background-color 1s;
}
.chef-desc-icons {
  margin-bottom: 35px;
}
.chef-login:hover {
  background-color: #2cbbbf;
  color: #191919;
}

/* Media Queries */

@media screen and (max-width: 550px) {
  .chef-img-arrow,
  .chef-blur {
    display: none;
  }
  .chef-img-pose {
    margin: 0;
    width: 90%;
  }
  .chef-desc {
    margin-top: 0;
  }
  .chef-desc-header {
    font-weight: 700;
    font-size: 2rem;
  }
  .chef-desc-para {
    /* padding: 2rem; */
    width: 90vw;
    height: 22vh;
    font-size: 1rem;
  }
  .chef-desc-list{
    font-size: 1rem;
  }
}

@media screen and (max-width:768px) {
  .chef-img-arrow,
  .chef-blur {
    display: none;
  }
  .chef-img-pose {
    margin: 0;
    width: 80%;
  }
  
}
