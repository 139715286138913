.Aboutus {
  padding-top: 3rem;
  background: linear-gradient(
    to right,
    rgba(28, 200, 200, 0.05),
    rgba(28, 200, 200, 0.25)
  );
  padding-top: 8%;
}

.Aboutus-Vision {
  margin-bottom: 250px;
  padding-top: 10px;
}
.Aboutus-Vision-header {
  font-size: 30px;
  font-weight: bolder;
  color: #2cbbbf;
}
.Aboutus-Vision-desc {
  font-size: 22px;
  font-weight: 400;
  padding: 10px 0px 10px 0px;
}
.Aboutus-Vision-img {
  max-width: 100%;
  border-radius: 30px;
}

.Aboutus-Mission {
  margin-bottom: 250px;
  padding-top: 20px;
  text-align: right;
}
.Aboutus-Mission-header {
  font-size: 35px;
  font-weight: bolder;
  color: #e32227;
}
.Aboutus-Mission-desc {
  font-size: 22px;
  font-weight: 400;
  text-align: right;
  padding: 10px 0px 10px 40px;
}
.Aboutus-para {
  margin-top: 20px;
}
.Aboutus-Mission-img {
  max-width: 100%;
  border-radius: 30px;
}

/*------------Media Queries---------*/
@media screen and (max-width: 550px) {
  .Aboutus {
    padding-top: 25%;
  }

  .Aboutus-Vision {
    margin-bottom: 50px;
  }
  .Aboutus-Vision-desc {
    font-size: 18px;
  }
  .Aboutus-Vision-img {
    margin-bottom: 50px;
  }

  .Aboutus-Mission-desc {
    font-size: 18px;
  }
}

@media screen and (max-width: 320px) {
 
  .Aboutus-Vision {
    margin-bottom: 50px;
    padding-top: 40%;
  }
}

@media screen and (max-width: 820px) {
  .Aboutus {
    padding-top: 15%;
  }
  .Aboutus-Vision {
    margin-bottom: 50px;
    padding-top: 10%;
  }
  .Aboutus-Vision-img {
    margin-bottom: 50px;
  }
  .Aboutus-Mission {
    margin-bottom: 50px;
  }
}
