.Restaurant-section {
  background: linear-gradient(
    to right,
    rgba(28, 200, 200, 0.05),
    rgba(28, 200, 200, 0.25)
  );
  padding: 12% 5%;
}

.Rest-header {
  overflow: hidden;
  max-width: 390px;
  min-height: 78px;
  font-size: 48px;
  font-weight: bolder;
  text-align: left;
  line-height: 50px;
  position: relative;
}
.Rest-box-1 {
  position: absolute;
  width: 14px;
  height: 14px;
  /* margin-left: 29%; */
  margin-bottom: 80px;
}
.Rest-dash {
  top: 85px;
  left: 0px;
  width: 125px;
  height: 12px;
  border-radius: 50px;
  margin-bottom: 7%;
  background: #01acb1;
}

.Rest-desc {
  overflow: hidden;
  font-family: Poppins;
  font-size: 20px;
  text-align: left;
  line-height: 54px;
  margin-top: -11.333333333333px;
  letter-spacing: 0.20000000298023px;
  color: #191919;
}
.Rest-btn {
  width: 119px;
  height: 32px;
  margin-top: 4%;
  border-radius: 40px;
  background-color: #e32227;
  color: #fff;
  font-weight: 600;
  border: none;
  padding: 10;
}
.Rest-btn:hover {
  transform: scale(1.1);
}
.Rest-blue-1 {
  width: 14px;
  height: 14px;
  margin-bottom: 100px;
}
.Rest-box-3 {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-left: 30%;
}
.Rest-box-4 {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-right: 90%;
  margin-top: 8%;
}
.Rest-video {
  max-width: 100%;
  margin-top: 18%;
  cursor: pointer;
}
.Rest-video-box-1 {
  width: 14px;
  height: 14px;
  margin-left: 55%;
  position: absolute;
}
.Rest-video-box-2 {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-right: 75%;
}
.Rest-video-box-3 {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: 40%;
}

.Rest-small-video {
  display: none;
}

/* Media Queries */

@media screen and (max-width: 550px) {
  .Rest .Rest-box-1,
  .Rest-blue-1,
  .Rest-box-3,
  .Rest-box-4,
  .Rest-btn,
  .Rest-btn:hover,
  .Rest-video-box-1,
  .Rest-video-box-2,
  .Rest-video-box-3,
  .Rest-play-btn,
  .Rest-video,
  .Rest-desc {
    display: none;
  }
  .Rest-header{
    font-size: 2.4rem;
    font-weight: 700;
  }
  .Rest-small-video {
    display: flex;
    max-width: 300px;
    min-height: 300px;
    cursor: pointer;
  }
}
