.Landing {
  background: linear-gradient(
    to right,
    rgba(154, 200, 28, 0.05),
    rgba(28, 200, 200, 0.25)
  );
  padding-top: 8%;
  font-family: Poppins;
  /* border: 1px solid red; */
}

.front-content-1 {
  display: flex;
  /* margin-top: 5px; */
  /* margin-bottom: 10px; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.front-img {
  padding: 25px;
  padding-left: 0;
  margin-left: 50px;
  margin-top: 10px;
  position: relative;
}
.front-orange {
  position: absolute;
  margin-left: 480px;
  margin-top: 80px;
  opacity: 0.8;
}

.front-text {
  margin-top: 5px;
  height: 14%;
  font-weight: 400;
  font-size: 16px;
  padding: 0px 70px 40px 0px;
  /* font-family: "poppins"; */
}

.front-buttons {
  /* margin-left: 40px; */
  /* margin-top: -35px; */
  max-width: 523px;
  height: 54px;
  /* border: 1px solid red; */
}

.front-buttons-line-1 {
  border-radius: 40px;
  background-color: transparent;
  border: 2px solid #71797e;
  font-size: 18px;
  font-weight: 400;
  /* margin-right: 50px; */
  padding: 10px 20px;
  font-family: "poppins";
  color: grey;
  cursor: pointer;
}

.front-buttons-line-1:focus {
  border-radius: 40px;
  background-color: #e32227;
  border: 2px solid #e32227;
  font-size: 18px;
  font-weight: 400;
  /* margin-right: 50px; */
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 1s;
}

.front-buttons-line-2 {
  border-radius: 40px;
  background-color: transparent;
  border: 2px solid #71797e;
  font-size: 18px;
  font-weight: 400;
  /* margin-right: 50px; */
  padding: 10px 20px;
  font-family: "poppins";
  color: grey;
  cursor: pointer;
}

.front-buttons-line-2:focus {
  border-radius: 40px;
  background-color: #e32227;
  border: 2px solid #e32227;
  font-size: 18px;
  font-weight: 400;
  /* margin-right: 50px; */
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 1s;
}

.front-buttons-line-3 {
  border-radius: 40px;
  background-color: transparent;
  border: 2px solid #71797e;
  font-size: 18px;
  font-weight: 400;
  /* margin-right: 50px; */
  padding: 10px 20px;
  font-family: "poppins";
  color: grey;
  cursor: pointer;
}

.front-buttons-line-3:focus {
  border-radius: 40px;
  background-color: #e32227;
  border: 2px solid #e32227;
  font-size: 18px;
  font-weight: 400;
  /* margin-right: 50px; */
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 1s;
}

.front-input {
  font-weight: 400;
  font-size: 18px;
  padding-left: 21px;
  line-height: 27px;
  width: 28rem;
  height: 54px;
  margin-top: 15px;
  /* margin-left: 40px; */
  font-family: "poppins";
  border-radius: 40px;
  border: 1px solid #191919;
  overflow: hidden;
  position: relative;
}

.front-search {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  background-color: turquoise;
  color: #fff;
  z-index: 1;
  position: absolute;
  margin-top: 21px;
  margin-left: -50px;
  padding: 10px;
  cursor: pointer;
}
.front-video {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: "poppins";
  width: 183px;
  height: 56px;
  margin-top: 20px;
  /* margin-left: 30px; */
  margin-right: 25px;
}
.front-video-text {
  color: #191919;
  opacity: 0.6;
  font-size: 17px;
  text-align: left;
  margin-top: 25px;
  font-weight: 600;
}
.front-play {
  background-color: #fff;
  width: 52px;
  height: 52px;
  padding: 0px;
  border-radius: 50%;
  border: none;
  color: #e32227;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.2);
}
.front-play:hover {
  transform: scale(1.2);
  cursor: pointer;
}
.front-down {
  width: 22px;
  height: 61px;
  margin-top: 65px;
  /* margin-left: 40px; */
  cursor: pointer;
  z-index: 1;
}
.front-down {
  animation: front-down 4s ease infinite;
}
@keyframes front-down {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.slider {
  position: relative;
}
.slider-hero {
  width: 100%;
  object-fit: cover;
}
.slider-uparrow {
  position: absolute;
  top: 1%;
  bottom: 0;
  right: 7%;
  width: 106px;
  height: 333px;
}
.slider-waves {
  position: absolute;
  z-index: -1;
  right: 0%;
  bottom: 2%;
}
.people-heart {
  width: 21px;
  height: 19px;
  margin-right: 5px;
}
.front-heading {
  font-weight: 700;
  font-size: 45px;
}
.front-heading-1 {
  color: #e32227;
}
.front-heading-2 {
  color: #2cbbbf;
}
.people {
  width: 160px;
  height: 28px;
  /* margin-left: 9%; */
  margin-top: 7%;
  background-color: #f5e4e4;
  border-radius: 40px;
  position: relative;
}

/*-------------------------- Media Queries------------------------------------ */

@media screen and (max-width: 550px) {
  .Landing{
    padding-top: 15%;
  }
  .front-content-1 {
    padding-top: 35%;
  }
  .Landing > .row {
    flex-direction: row-reverse !important;
  }
  .carousel slide {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    height: 10vh !important;
    /* border: 1px solid red; */
  }
  .front-heading {
    font-size: 1.9rem;
    font-weight: 650;
    height: 10vh;
  }
  .front-text {
    font-size: 12px;
    padding: 0px 0px 0px 0px;
  }

  .front-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .front-buttons-line-1,
  .front-buttons-line-1:focus,
  .front-buttons-line-2,
  .front-buttons-line-2:focus,
  .front-buttons-line-3,
  .front-buttons-line-3:focus {
    /* margin-top: 1rem; */
    font-size: 12px;
  }
  .front-buttons-line-1,
  .front-buttons-line-1:focus {
    height: 5vh;
    max-width: 40vw;
    margin-right: 2rem;
  }
  .front-buttons-line-3 {
    margin-top: 8rem;
  }
  .front-buttons-line-3:focus {
    border-radius: 40px;
    border: 2px solid #e32227;
    font-size: 18px;
    font-weight: 400;
    /* margin-right: 50px; */
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
    overflow: hidden;
    transition: background-color 1s;
  }

  .front-input {
    width: 18rem;
    margin-top: 5.2rem;
  }
  .front-search {
    margin-top: 5.5rem;
  }
  .front-video {
    margin-top: -8rem;
  }
  .front-video-text {
    font-size: 12px;
  }
  .slider-hero {
    margin-top: 5rem;
  }
  .slider-waves,
  .slider-uparrow,
  .front-orange,
  .front-img,
  .front-dash,
  .front-down {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .slider-waves,
  .slider-uparrow,
  .front-orange,
  .front-img,
  .front-dash,
  .front-down {
    display: none;
  }
  .front-content-1 {
    padding-top: 10%;
  }
}


.reals video {
  border-radius: 15px;
  margin-top: 60px;
  margin-bottom: 50px;
}